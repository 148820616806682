<template>
  <main class="calfh" v-scrollspy>
    <section class="cnvmw" v-masonry data-masonry>
      <div class="c6t7c cz0xq cb1an cl1vg czzwo c8hf0 c1fvq" aria-hidden="true">
        <img class="cd1c2" src="@/assets/images/stripes.svg" width="768" height="432" alt="Stripes">
      </div>

      <div class="c6t7c cz0xq c5f38 cb1an czzwo cbx03" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c3cj0 c14i2 c7qgr"></div>
      </div>
      <div class="c6t7c cz0xq caonz cc829 cb1an czzwo" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 c3cj0 c14i2 c7qgr"></div>
      </div>
      <div class="c6t7c cz0xq c0pvq ccg3y cb1an czzwo" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 c3cj0 c14i2 c7qgr"></div>
      </div>

      <div class="c51vq cfz40 c9l28 copk9">

        <div class="c7vra cwv1a cpyqd clphe">

          <div class="ckgn9 cgile cpyqd">
            <h1 class="cu5ex cqlk5 cbl2u c8fk6 ceq2f czoh8" data-aos="zoom-y-out" data-aos-delay="150">Seu <span class="cq8u8">parceiro</span> de <br class="ce2tn">excelência</h1>
            <div class="ce5lh c9l28">
              <p class="cnkw4 c125h cjrsd" data-aos="zoom-y-out" data-aos-delay="300">Edinte é uma empresa de serviços de TI que oferece soluções de TI abrangentes para atender à clientela em Moçambique.</p>
              <div class="cwwgu cpa9w cbkqt ch7ua cnvmw">
                <div class="c8hib ct30u cdiu1 cfr0l c9l28" data-aos="zoom-y-out" data-aos-delay="450">
                  <router-link
                      :to="{ name: 'HomePage', hash: '#contact' }"
                      class="cl5kc cgko6 cebtw c0ood cuq9y cy22h c6i6l cdxq3 cksb3 ctxdt cpx7z cavkg cktd8 cs8g5"
                  >
                    <span class="co7ag cw01g cnvmw">
                        Começar <span class="cg0uc cy04x csq29 cxzl0 cb0m7">-&gt;</span>
                    </span>
                  </router-link>
                  <router-link
                      :to="{ name: 'HomePage', hash: '#services' }"
                      class="cs78l coqce cdxq3 cfm51 c0u6z ctxdt cpx7z cs8g5"
                  >
                    Serviços
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="ce5lh c9l28" data-aos="zoom-y-out" data-aos-delay="600">
            <div class="cwwgu cpzpp cboo1 cpa9w cz3us cbkqt cf3d9 caizk clz0w clm9c cztcs cq997 c7ht5 consw cnvmw cs3xh cp3xx">
              <div class="ctgmj ct1yq cy1as cuxkn cxk70 cqo02 cary2 co7ag cnvmw cjrsd cfqvj">
                <span class="c6ild cbhyt c6i6l">edinte.com</span>
              </div>
              <div class="cvnvi c5t0b c81ur c8gkw">
                <span class="ci8qg c7wsw">Bem-vindo à Edinte - Transformamos desafios em soluções inovadoras de TI. 🌐</span><br>
                <span class="c8cop">request</span><br><br>
                <span class="c4shd c7wsw">Nossa equipe de especialistas em TI está pronta para entender suas necessidades e desenvolver estratégias sob medida para seu negócio. 💼</span><br>
                <span class="cc78u">request</span><br><br>
                <span class="ci5wc c7wsw">Fale conosco hoje mesmo para descobrir como podemos ajudar sua empresa a alcançar novos patamares de eficiência e inovação. ✉️</span><br>
                <span class="cbgye">successful</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <section>
      <div class="c51vq cfz40 c9l28 copk9">
        <div>
          <div>
            <div class="c3sc6 cfqvj">
              <div
                  role="tablist"
                  class="chnmm c6433 cyo1m cvi8z cboo1 cy4zf cgb32 ch8zk cf1pq cpa9w ch7ua c3sc6 case2 cw01g c1wcu cfpf9 cmhvg cnvmw cfm51 cjrsd c7hzr"
              >
                <p>Tecnologias usadas na Edinte</p>
              </div>
            </div>

            <div class="c3sc6 co7ag clf2b cnvmw cfqvj">
              <div class="czzwo c1fvq">
                <svg
                    class="c8y72"
                    xmlns="http://www.w3.org/2000/svg"
                    width="164"
                    height="41"
                    viewBox="0 0 164 41"
                    fill="none"
                >
                  <circle cx="1" cy="8" r="1" fill-opacity="0.24"></circle>
                  <circle cx="1" cy="1" r="1" fill-opacity="0.16"></circle>
                  <circle cx="1" cy="15" r="1"></circle>
                  <circle cx="1" cy="26" r="1" fill-opacity="0.64"></circle>
                  <circle cx="1" cy="33" r="1" fill-opacity="0.24"></circle>
                  <circle cx="8" cy="8" r="1"></circle>
                  <circle cx="8" cy="15" r="1"></circle>
                  <circle cx="8" cy="26" r="1" fill-opacity="0.24"></circle>
                  <circle
                      cx="15"
                      cy="15"
                      r="1"
                      fill-opacity="0.64"
                  ></circle>
                  <circle
                      cx="15"
                      cy="26"
                      r="1"
                      fill-opacity="0.16"
                  ></circle>
                  <circle cx="8" cy="33" r="1"></circle>
                  <circle cx="1" cy="40" r="1"></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 7)"
                      fill-opacity="0.24"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 0)"
                      fill-opacity="0.16"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 14)"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 25)"
                      fill-opacity="0.64"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 32)"
                      fill-opacity="0.24"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 157 7)"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 157 14)"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 157 25)"
                      fill-opacity="0.24"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 150 14)"
                      fill-opacity="0.64"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 150 25)"
                      fill-opacity="0.16"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 157 32)"
                  ></circle>
                  <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(-1 0 0 1 164 39)"
                  ></circle>
                </svg>
              </div>
              <!-- Blue glow -->
              <div class="czzwo c1fvq">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="432"
                    height="160"
                    viewBox="0 0 432 160"
                    fill="none"
                >
                  <g opacity="0.6" filter="url(#filter0_f_2044_9)">
                    <path
                        class="c8y72"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M80 112C62.3269 112 48 97.6731 48 80C48 62.3269 62.3269 48 80 48C97.6731 48 171 62.3269 171 80C171 97.6731 97.6731 112 80 112ZM352 112C369.673 112 384 97.6731 384 80C384 62.3269 369.673 48 352 48C334.327 48 261 62.3269 261 80C261 97.6731 334.327 112 352 112Z"
                    ></path>
                  </g>
                  <defs>
                    <filter
                        id="filter0_f_2044_9"
                        x="0"
                        y="0"
                        width="432"
                        height="160"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                      <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                      ></feFlood>
                      <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                      ></feBlend>
                      <feGaussianBlur
                          stdDeviation="32"
                          result="effect1_foregroundBlur_2044_9"
                      ></feGaussianBlur>
                    </filter>
                  </defs>
                </svg>
              </div>
              <div
                  class="cf15q cuczk cn9i8 cpisu c4466 c0ahy czzwo c8hf0 c1fvq c2fw4"
              ></div>
              <div
                  class="cf15q cuczk cn9i8 cpisu c4466 c0ahy czzwo cw1if c1fvq c2fw4"
              ></div>
              <div
                  class="cf15q ctme4 cuczk cn9i8 c0lv3 cpisu czzwo cllmx c1fvq c2fw4"
              ></div>
              <div
                  class="cmhoq cuplw cv5is cx8vg cf15q c5v8z cuczk cn9i8 cpa9w cpisu c4466 c2rcs c0ahy czzwo cllmx c1fvq c2fw4"
              ></div>
              <div
                  class="c1f7y cuplw c99fu cx8vg cf15q c5v8z cuczk cn9i8 cpa9w cpisu c4466 c2rcs c0ahy czzwo cllmx c1fvq c2fw4"
              ></div>
              <div
                  class="cf15q c65vs c5oqp cuczk cn9i8 cpisu c4466 czzwo cllmx c1fvq c2fw4"
              ></div>
              <div
                  class="cf15q c65vs ccohl cuczk cn9i8 cpisu c4466 czzwo cllmx c1fvq c2fw4"
              ></div>
              <div
                  class="cnxsm cf15q cn9hh cpisu c33dr cqtqd cb1an czzwo c1fvq csuu2"
              ></div>
              <div
                  class="c9cwo cf15q cebtw cpisu c33dr cqtqd cb1an czzwo c1fvq csuu2"
              ></div>
              <div
                  class="ck8gc c6433 cyo1m csmuh cgb32 cws6p cpa9w czm9e case2 czzwo"
              >
                <div class="crg5p">
                  <div
                      class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj cdotj cpr8l"
                  >
                    <img
                        class="cnvmw"
                        src="@/assets/images/logo/Perfil%20(1).jpg"
                        width="72"
                        height="72"
                        alt="Logo 01"
                        style="border-radius: 50%"
                    />
                  </div>
                </div>
              </div>
              <div
                  class="c3sc6 co7ag c53gg ctxdt cfqvj"
                  role="tabpanel"
                  tabindex="0"
                  aria-labelledby="tab-1"
              >
                <div class="cenbq czzwo">
                  <div class="cdkaa">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj cpxb2 cmf0o"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/laravel-svgrepo-com.svg"
                          width="40"
                          height="40"
                          alt="Logo 02"
                      />
                    </div>
                  </div>
                </div>
                <div class="cw8un czzwo">
                  <div class="cgci7">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj cpxb2 cmf0o"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/vue-svgrepo-com.svg"
                          width="40"
                          height="40"
                          alt="Logo 03"
                      />
                    </div>
                  </div>
                </div>
                <div class="cnxsm cv5is czzwo">
                  <div class="c576c">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj c7xl0 ct6tg"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/php3-svgrepo-com.svg"
                          width="50"
                          height="50"
                          alt="Logo 04"
                      />
                    </div>
                  </div>
                </div>
                <div class="cv5is c9cwo czzwo">
                  <div class="cwe12">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj c7xl0 ct6tg"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/java-svgrepo-com.svg"
                          width="50"
                          height="50"
                          alt="Logo 05"
                      />
                    </div>
                  </div>
                </div>
                <div class="c9cwo c99fu czzwo">
                  <div class="cruuw">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj c7xl0 ct6tg"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/js-official-svgrepo-com.svg"
                          width="50"
                          height="50"
                          alt="Logo 06"
                          style="border-radius: 50%"
                      />
                    </div>
                  </div>
                </div>
                <div class="cnxsm c99fu czzwo">
                  <div class="ctt0y">
                    <div
                        class="c5rta cy4zf crmb5 ch8zk cggr1 cncql cpa9w ch7ua c3sc6 case2 co7ag cqu42 cmhvg cfm51 cfqvj c7xl0 ct6tg"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/react-svgrepo-com.svg"
                          width="50"
                          height="50"
                          alt="Logo 07"
                      />
                    </div>
                  </div>
                </div>
                <div class="cemll cvlc2 czzwo">
                  <div class="cruuw">
                    <div
                        class="cbtiq c3sc6 co7ag cqu42 cmhvg cfm51 cle8v cfqvj ciw3g cnxbu"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/dev-ops-solid-svgrepo-com.svg"
                          width="20"
                          height="20"
                          alt="Logo 08"
                      />
                    </div>
                  </div>
                </div>
                <div class="cbb9p cvlc2 czzwo">
                  <div class="ccuhn">
                    <div
                        class="cbtiq c3sc6 co7ag cqu42 cmhvg cfm51 cle8v cfqvj ciw3g cnxbu"
                    >
                      <img
                          class="cnvmw"
                          src="@/assets/images/tech/spring-boot-svgrepo-com.svg"
                          width="21"
                          height="13"
                          alt="Logo 09"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="c7vd7 cpa9w ch7ua czx54 cnvmw" id="services">
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="cyhva cu6qt">

          <div class="ckgn9 ce5lh c7vra c9l28 c6ybg">
            <h2 class="c7wsw c6lc4 cbl2u cx14y c0eoy c8fk6">Nossos Serviços</h2>
          </div>
          <div class="overflow-hidden chmjk cyqny c2dad cqqgh ci0m4 cvfjt cho3t cns5a cmf6a crbni csgrw chkwg cddvk c50vs cy4l5 cepbw c22g3 c3hsv">
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Zm2-4a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm1 10a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z"></path>
                </svg>
                <span>Desenvolvimento de Aplicações Móveis</span>
              </h3>
              <p class="c68fx cn3iu">Na Edinte, criamos aplicações móveis para iOS e Android, tanto nativas com Swift e Kotlin, como híbridas com React Native. Focamos em interfaces intuitivas e experiências de utilizador envolventes.</p>
            </article>
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M14.29 2.614a1 1 0 0 0-1.58-1.228L6.407 9.492l-3.199-3.2a1 1 0 1 0-1.414 1.415l4 4a1 1 0 0 0 1.496-.093l7-9ZM1 14a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H1Z"></path>
                </svg>
                <span>Desenvolvimento de Sistemas de Gestão</span>
              </h3>
              <p class="c68fx cn3iu">Oferecemos sistemas ERP e CRM personalizados, além de soluções de gestão de projetos, para otimizar operações, finanças e relacionamento com clientes.</p>
            </article>
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M2.248 6.285a1 1 0 0 1-1.916-.57A8.014 8.014 0 0 1 5.715.332a1 1 0 0 1 .57 1.916 6.014 6.014 0 0 0-4.037 4.037Z" opacity=".3"></path>
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1.715-6.752a1 1 0 0 1 .57-1.916 8.014 8.014 0 0 1 5.383 5.383 1 1 0 1 1-1.916.57 6.014 6.014 0 0 0-4.037-4.037Zm4.037 7.467a1 1 0 1 1 1.916.57 8.014 8.014 0 0 1-5.383 5.383 1 1 0 1 1-.57-1.916 6.014 6.014 0 0 0 4.037-4.037Zm-7.467 4.037a1 1 0 1 1-.57 1.916 8.014 8.014 0 0 1-5.383-5.383 1 1 0 1 1 1.916-.57 6.014 6.014 0 0 0 4.037 4.037Z"></path>
                </svg>
                <span>Desenvolvimento de Soluções Web</span>
              </h3>
              <p class="c68fx cn3iu">Desenvolvemos aplicações web escaláveis e plataformas de e-commerce utilizando Vue.js, garantindo uma experiência online segura e eficiente.</p>
            </article>
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M8 0a1 1 0 0 1 1 1v14a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1Zm6 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h1a1 1 0 1 1 0 2h-1a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h1a1 1 0 1 1 0 2h-1ZM1 1a1 1 0 0 0 0 2h1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 1 0 0 2h1a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H1Z"></path>
                </svg>
                <span>Integração e Automação</span>
              </h3>
              <p class="c68fx cn3iu">Facilitamos a integração de sistemas através de APIs e serviços, e automatizamos processos empresariais para aumentar a eficiência.</p>
            </article>
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M10.284.33a1 1 0 1 0-.574 1.917 6.049 6.049 0 0 1 2.417 1.395A1 1 0 0 0 13.5 2.188 8.034 8.034 0 0 0 10.284.33ZM6.288 2.248A1 1 0 0 0 5.718.33 8.036 8.036 0 0 0 2.5 2.187a1 1 0 0 0 1.372 1.455 6.036 6.036 0 0 1 2.415-1.395ZM1.42 5.401a1 1 0 0 1 .742 1.204 6.025 6.025 0 0 0 0 2.79 1 1 0 0 1-1.946.462 8.026 8.026 0 0 1 0-3.714A1 1 0 0 1 1.421 5.4Zm2.452 6.957A1 1 0 0 0 2.5 13.812a8.036 8.036 0 0 0 3.216 1.857 1 1 0 0 0 .574-1.916 6.044 6.044 0 0 1-2.417-1.395Zm9.668.04a1 1 0 0 1-.041 1.414 8.033 8.033 0 0 1-3.217 1.857 1 1 0 1 1-.571-1.917 6.035 6.035 0 0 0 2.415-1.395 1 1 0 0 1 1.414.042Zm2.242-6.255a1 1 0 1 0-1.946.462 6.03 6.03 0 0 1 0 2.79 1 1 0 1 0 1.946.462 8.022 8.022 0 0 0 0-3.714Z"></path>
                </svg>
                <span>Consultoria e Arquitetura de Software</span>
              </h3>
              <p class="c68fx cn3iu">Fornecemos consultoria para definir arquiteturas de software e otimizar o desempenho dos sistemas existentes.</p>
            </article>
            <article>
              <h3 class="c7wsw co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M9 1a1 1 0 1 0-2 0v6a1 1 0 0 0 2 0V1ZM4.572 3.08a1 1 0 0 0-1.144-1.64A7.987 7.987 0 0 0 0 8a8 8 0 0 0 16 0c0-2.72-1.36-5.117-3.428-6.56a1 1 0 1 0-1.144 1.64A5.987 5.987 0 0 1 14 8 6 6 0 1 1 2 8a5.987 5.987 0 0 1 2.572-4.92Z"></path>
                </svg>
                <span>Design e Experiência do Utilizador (UX/UI)</span>
              </h3>
              <p class="c68fx cn3iu">Desenvolvemos interfaces de utilizador apelativas e realizamos testes de usabilidade para garantir uma experiência excecional.</p>
            </article>
          </div>

        </div>
      </div>
    </section>

    <section class="cnvmw">
      <div class="cz0xq c0tot cb1an czzwo c8hf0 c1fvq" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 cvlc2 c14i2 c7qgr"></div>
      </div>
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="cyhva cu6qt">

          <div class="ckgn9 ce5lh crglh c9l28 c133p">
            <h2 class="c6lc4 cbl2u cx14y cktd8 c0eoy c8fk6">Portfólio</h2>
            <p class="cnkw4 c125h">
              Explora alguns dos nossos trabalhos.
            </p>
          </div>

          <div class="c9l28 c51vq copk9 cfz40">
            <div class="cpyqd c7vra">
              <div class="cn2tb">
                <section>
                  <div class="c3hsv cx47t cy4l5 cgxz0 cepbw">
                    <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                      <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                        <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                      </svg>
                      <div class="cp91v cw01g">
                        <div class="cnvmw">
                          <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-creditflow.png" width="48" height="48" alt="Icon 01">
                        </div>
                      </div>
                      <h3 class="css88">
                        <a class="cbl2u cpa9w ch7ua" target="_blank" href="https://www.creditflowmz.com/">Creditflow</a>
                      </h3>
                      <p class="c8gkw cnkw4">Sistema de gestão de microcrédito que inclui cadastro de clientes, processamento de empréstimos, contabilidade, relatórios detalhados, conformidade regulatória e muito mais...</p>
                    </article>
                    <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                      <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                        <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                      </svg>
                      <div class="cp91v cw01g">
                        <div class="cnvmw">
                          <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-smartwater.png" width="44" height="44" alt="Icon 02">
                        </div>
                      </div>
                      <h3 class="css88">
                        <a class="cbl2u cpa9w ch7ua" href="#0">Smartwater</a>
                      </h3>
                      <p class="c8gkw cnkw4">Sistema de gestão de distribuição de água que inclui controle de recursos, monitoramento de consumo e geração de relatórios detalhados para otimização do uso e manutenção da infraestrutura...</p>
                    </article>
                    <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                      <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                        <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                      </svg>
                      <div class="cp91v cw01g">
                        <div class="cnvmw">
                          <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-sintega.png" width="48" height="48" alt="Icon 03">
                        </div>
                      </div>
                      <h3 class="css88">
                        <a class="cbl2u cpa9w ch7ua" href="#0">Sintega</a>
                      </h3>
                      <p class="c8gkw cnkw4">Sistema integrado de gestão académica que abrange matrícula, registros, avaliação, controle financeiro, relatórios e comunicação entre alunos, professores, administração e muito mais...</p>
                    </article>

                  </div>
                </section>
              </div>
              <div class="c796f cyhva cu6qt copk9" style="margin-bottom: -150px">
                <div class="c8hib ct30u cdiu1 cfr0l c9l28">
                  <router-link
                      :to="{ name: 'PortfolioPage'}"
                      class="cl5kc cgko6 cebtw c0ood cuq9y cy22h c6i6l cdxq3 cksb3 ctxdt cpx7z cavkg cktd8 cs8g5"
                  >
                   <span class="co7ag cw01g cnvmw">
                      Ver mais projectos <span class="cg0uc cy04x csq29 cxzl0 cb0m7">-&gt;</span>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="cnvmw   ">
      <div class="cz0xq c0tot cb1an czzwo c8hf0 c1fvq" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 cvlc2 c14i2 c7qgr"></div>
      </div>
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="cyhva cu6qt">

          <div class="ckgn9 ce5lh crglh c9l28 c133p">
            <h2 class="c6lc4 cbl2u cx14y cktd8 c0eoy c8fk6">Por que Escolher-Nos?</h2>
            <p class="cnkw4 c125h">
              O Nosso DNA: Inovação, Paixão e Resultados. Somos uma equipa apaixonada e inovadora que vai além das expectativas tradicionais. Combinamos a nossa paixão pela tecnologia com um profundo entendimento das necessidades dos clientes, transformando ideias em soluções impactantes. Escolher-nos significa optar pela excelência, melhoria contínua e a garantia de que a sua visão se tornará realidade. Juntos, estamos moldando o futuro da tecnologia e inovação, e estamos empenhados em tornar cada escolha significativa.
            </p>
          </div>

          <div class="overflow-hidden ctncy chmjk c2dad cprw1 cho3t cy3v2 ccslj cgnw0 csgrw c8hgl ckow0 cddvk cepbw c22g3 c8fk6 c3hsv">
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="m15.447 6.605-.673-.336a6.973 6.973 0 0 0-.761-1.834l.238-.715a.999.999 0 0 0-.242-1.023l-.707-.707a.995.995 0 0 0-1.023-.242l-.715.238a6.96 6.96 0 0 0-1.834-.761L9.394.552A1 1 0 0 0 8.5-.001h-1c-.379 0-.725.214-.895.553l-.336.673a6.973 6.973 0 0 0-1.834.761l-.715-.238a.997.997 0 0 0-1.023.242l-.707.707a1.001 1.001 0 0 0-.242 1.023l.238.715a6.959 6.959 0 0 0-.761 1.834l-.673.336a1 1 0 0 0-.553.895v1c0 .379.214.725.553.895l.673.336c.167.653.425 1.268.761 1.834l-.238.715a.999.999 0 0 0 .242 1.023l.707.707a.997.997 0 0 0 1.023.242l.715-.238a6.959 6.959 0 0 0 1.834.761l.336.673a1 1 0 0 0 .895.553h1c.379 0 .725-.214.895-.553l.336-.673a6.973 6.973 0 0 0 1.834-.761l.715.238a1.001 1.001 0 0 0 1.023-.242l.707-.707c.268-.268.361-.664.242-1.023l-.238-.715a6.959 6.959 0 0 0 .761-1.834l.673-.336A1 1 0 0 0 16 8.5v-1c0-.379-.214-.725-.553-.895ZM8 13a5 5 0 1 1 .001-10.001 5 5 0 0 1 0 10.001Z"></path>
                </svg>
                <span>Excelência na Experiência</span>
              </h3>
              <p class="c68fx cnkw4">
                <ul style="list-style: decimal-leading-zero" class="c68fx cnkw4">
                  <li>Temos uma equipa experiente e talentosa de desenvolvedores</li>
                  <li>Temos um histórico comprovado de entrega de resultados de alta qualidade</li>
                  <li>Estamos comprometidos em alcançar resultados excepcionais em cada projecto</li>
                </ul>
              </p>
            </article>
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="12">
                  <path d="M2 0a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2H2Zm0 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Zm1-3a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H3Z"></path>
                </svg>
                <span>Inovação em Nosso DNA</span>
              </h3>
              <p class="c68fx cnkw4">
                <ul style="list-style: decimal-leading-zero" class="c68fx cnkw4">
                  <li>Abraçamos a inovação como parte fundamental da nossa cultura</li>
                  <li>Mantemo-nos atualizados com as últimas tendências e tecnologias</li>
                  <li>Mantemo-nos atualizados com as últimas tendências e tecnologias</li>
                </ul>
              </p>
            </article>
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M14.75 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 13.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.5 14.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM1.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z"></path>
                </svg>
                <span>Compromisso com o Sucesso do Cliente</span>
              </h3>
              <p class="c68fx cnkw4">
                <ul style="list-style: decimal-leading-zero;" class="c68fx cnkw4">
                  <li>Damos prioridade aos interesses dos nossos clientes</li>
                  <li>Trabalhamos em parceria, ouvindo e compreendendo as suas necessidades</li>
                  <li>Dedicamo-nos a garantir que cada projeto alcance os seus objetivos e supere as expectativas</li>
                </ul>
              </p>
            </article>
          </div>

        </div>
      </div>
    </section>

    <SlideSection
        title="Nossos Clientes"
        :images="clientImages"
    />

    <section id="contact" data-scrollspy-target>
      <div class="cnvmw cfqvj calfh">

        <div class="c6t7c c9bck czzwo cw1if coaxx" aria-hidden="true">
          <div class="ch2p1 clmcj cp9h9 cqu42 czrg5 c14i2 c7qgr"></div>
        </div>

        <div class="ctxdt">

          <div class="czzpr cyrrz c3sc6 c7vf3 cgnth ctath c53gg cfqvj">

            <div class="cfz40 copk9">
              <div class="c4m5e c9l28 ctxdt">
                <div class="cyhva cr6ms">

                  <div class="cpl20">
                    <h1 class="cbl2u c459b c0eoy c8fk6">Fale Connosco</h1>
                  </div>

                  <form action="https://formsubmit.co/4e4e165474a44cbcd9705fd737f67a5a"
                        method="POST"
                  >
                    <input type="hidden" name="_template" value="table">
                    <div class="ca8fd">
                      <div>
                        <label class="cnkw4 cbhyt c8gkw cbkh6 css88" for="name">Nome Completo</label>
                        <input id="name" class="cc1q8 ctxdt co8y1" name="nome" type="text" placeholder="Seu nome" required="required">
                      </div>
                      <div>
                        <label class="cnkw4 cbhyt c8gkw cbkh6 css88" for="email">Email</label>
                        <input id="email" class="cc1q8 ctxdt co8y1" name="email" type="email" placeholder="Seu email" required="required">
                      </div>
                      <div>
                        <label class="cnkw4 cbhyt c8gkw cbkh6 css88" for="phone">Celular</label>
                        <input id="phone" class="cc1q8 ctxdt co8y1" name="celular" type="number" min="99999999" max="999999999" placeholder="84XXXXXXXX" required="required">
                      </div>
                      <div>
                        <label class="cnkw4 cbhyt c8gkw cbkh6 css88" for="message">Mensagem</label>
                        <textarea id="message" class="cc1q8 ctxdt co8y1" name="mensagem" required="required"></textarea>
                      </div>
                    </div>
                    <div class="ce8yz ckcqq">
                      <button class="cl5kc cgko6 cebtw c0ood cuq9y cy22h c6i6l ctxdt cpx7z cs8g5">Enviar Mensagem</button>
                    </div>
                    <input type="hidden" name="_autoresponse" value="Seu email foi recebido com sucesso!">
                    <input type="hidden" name="_subject" value="INFO GERAL!">
                    <input type="hidden" name="_next" value="https://edinte.com/">
                  </form>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="overflow-hidden cfngb cq997 czox6 cnvmw cokse ctqun crz9t c64qn">
          <div class="c6t7c cz0xq c0tot cfme0 cb1an czzwo cllmx c3gpq c1fvq" aria-hidden="true">
            <img src="@/assets/images/auth-bg.svg" class="cd1c2" width="1285" height="1684" alt="Auth bg">
          </div>
          <div class="c0tot cc99p czzwo cllmx c084q">
            <div class="clm9c cyalt cq997 c7ht5 cj7l9 consw ctxdt cs3xh cp3xx">
              <div class="cvnvi c5t0b cyalt cj7l9 c81ur c8gkw " style="display: flex; flex-direction: column; gap: 50px; font-size: 1.5rem;">
                <p class="c7wsw"><i class="fa-solid fa-phone"></i> +258 87 778 2067</p>
                <p class="c7wsw"><i class="fa-solid fa-envelope"></i> info@edinte.co.mz</p>
                <p class="c7wsw"><i class="fa-solid fa-location-dot"></i> Avenida Julius Nyerere, n.º 3453, Maputo, Moçambique</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section >
    <section>
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="overflow-hidden cboo1 ce925 c7vd7 cpa9w ch7ua cwsi8 cq997 ckgn9 consw cnvmw" data-aos="zoom-y-out">
          <div class="cz0xq crou0 cb1an czzwo cw1if c1fvq" aria-hidden="true">
            <div class="ck1ij c3ayk cqu42 c05zd copal c48hl"></div>
          </div>
          <div class="c6t7c cz0xq cb1an cl1vg czzwo c8hf0 c1fvq" aria-hidden="true">
            <img class="cd1c2" src="@/assets/images/stripes-dark.svg" width="768" height="432" alt="Stripes">
          </div>
          <div class="c796f cyhva cu6qt copk9">
            <h2 class="c0eoy c7wsw c6lc4 cbl2u cs9iq c8fk6 cx14y czoh8">Tem algum projecto em mente?</h2>
            <div class="c8hib ct30u cdiu1 cfr0l c9l28">
              <router-link
                  :to="{ name: 'HomePage', hash: '#contact' }"
                  class="cl5kc cgko6 cebtw c0ood cuq9y cy22h c6i6l cdxq3 cksb3 ctxdt cpx7z cavkg cktd8 cs8g5"
              >
                <span class="co7ag cw01g cnvmw">
                  Contacte-nos <span class="cg0uc cy04x csq29 cxzl0 cb0m7">-&gt;</span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SlideSection data-scrollspy-target
        title="Nossos Parceiros"
        :images="partnerImages"
    />
  </main>
</template>

<script>
  // import HeaderComponent from '@/components/HeaderComponent.vue'
  // import FooterComponent from '@/components/FooterComponent.vue'
  import SlideSection from "@/components/SlideSection.vue";
  import AOS from 'aos';
  import 'aos/dist/aos.css';

  export default {
    name: 'HomePage',
    head() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Serviços - Edinte",
              "description": "Explore os serviços oferecidos pela Edinte. Oferecemos soluções de desenvolvimento de software, gestão de microcrédito e mais.",
              "url": "https://edinte.co.mz/#services"
            }
          },
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Contacto - Edinte",
              "description": "Entre em contacto com a Edinte. Estamos prontos para ajudar com suas necessidades de desenvolvimento e microcrédito.",
              "url": "https://edinte.co.mz/#contact"
            }
          }
        ]
      }
    },
    components: {
      SlideSection,
    },
    data() {
      return {
        partnerImages: [
          { src: require('@/assets/images/partner/info24.jpg'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/espaco inovacao.png'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/info24.jpg'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/incubadora uem.jpg'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/info24.jpg'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/espaco inovacao.png'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/info24.jpg'), alt: 'Parceiro' },
          { src: require('@/assets/images/partner/incubadora uem.jpg'), alt: 'Parceiro' },
        ],
        clientImages: [
          { src: require('@/assets/images/logo/Perfil (1).jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/customer/sosamp.jpeg'), alt: 'Cliente' },
          { src: require('@/assets/images/logo/Perfil (2).jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/customer/capital.jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/logo/Perfil (1).jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/customer/vertical.jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/logo/Perfil (2).jpg'), alt: 'Cliente' },
          { src: require('@/assets/images/customer/KJ.jpg'), alt: 'Cliente' },
        ],
      };
    },
    mounted() {
      AOS.init();
    }
  }
</script>

<style scoped>

</style>